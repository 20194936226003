@tailwind base;
@tailwind components;
@tailwind utilities;

.app-container {
  max-width: 90%;
  margin: 0 auto;
}

* {
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

body {
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
}

.hero {
  background-image: url("./images/Hero.webp");
  height: calc(100vh);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

@media (max-width: 600px) {
  .hero {
    height: 23vh;
    background-size: 100% auto;
  }
}

.team {
  /* background-image: url("./images/team.jpg"); */
  /* height: calc(100vh - 112px); */
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 600px) {
  .team {
    height: 100%;
    background-position: bottom;
  }
}

#testimonials .owl-prev,
#testimonials .owl-next {
  font-size: 30px !important;
  padding: 0px 10px !important;
  color: white !important;
}

.slider-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 112px);
  transition: opacity 5s ease-in-out;
}

.image {
  width: 100%;
  height: 300px; /* Set your desired image height */
  background-size: cover;
  background-position: center;
}

.text {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
  transition: opacity 1s ease-in-out;
}

.text h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.text p {
  font-size: 16px;
}

/* Additional styles for transitions */
.slider-section.fade-enter {
  opacity: 0;
}

.slider-section.fade-enter-active {
  opacity: 1;
}

.slider-section.fade-exit {
  opacity: 1;
}

.slider-section.fade-exit-active {
  opacity: 0;
}

.text {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Add transform property */
  transform: translateY(0); /* Initial position */
}

/* Add additional styles for transitions */
.slider-section.fade-enter .text {
  opacity: 0;
  transform: translateY(100%); /* Move text off-screen initially */
}

.slider-section.fade-enter-active .text {
  opacity: 1;
  transform: translateY(0); /* Move text back to its original position */
}

.slider-section.fade-exit .text {
  opacity: 1;
  transform: translateY(0); /* Keep text in its original position during exit */
}

.slider-section.fade-exit-active .text {
  opacity: 0;
  transform: translateY(-100%); /* Move text off-screen during exit */
}
